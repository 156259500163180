"use client";

const days = 24 * 3 * 60 * 60 * 1000; // 3 días en milisegundos

// Función auxiliar para manejar el almacenamiento en sessionStorage con expiración
function setSessionItem(key, value) {
  const item = {
    value,
    expiry: Date.now() + days
  };
  sessionStorage.setItem(key, JSON.stringify(item));
}

function getSessionItem(key) {
  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  if (Date.now() > item.expiry) {
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
}

// Funciones de almacenamiento en sessionStorage
export async function setCookieToken(token) {
  // Si estás usando cookies, necesitas usar una librería como js-cookie para gestionar cookies en el navegador
  document.cookie = `token=${1}; path=/; sameSite=strict; secure;`;

  setSessionItem("token", token);
}

export async function setCookiePaginas1(paginas1) {
  setSessionItem("paginas1", paginas1);
}

export async function setCookiePaginas2(paginas2) {
  setSessionItem("paginas2", paginas2);
}

export async function setCookieProveedores(proveedores) {
  setSessionItem("proveedores", proveedores);
}

export async function setCookieNotificaciones(notificacion) {
  setSessionItem("notificaciones", notificacion);
}

export async function setCookieAdministrador(nombre, puestoLaboral, rolId, proveedorId, sucursalId) {
  setSessionItem("nombre", nombre);
  setSessionItem("puestoLaboral", puestoLaboral);
  setSessionItem("rolId", rolId);
  setSessionItem("proveedorId", proveedorId);
  setSessionItem("sucursalId", sucursalId);
}

export async function setCookieSucursales(sucursales) {
  setSessionItem("sucursales", sucursales);
}

// Funciones de recuperación de sessionStorage
export async function getCookieToken() {
  return getSessionItem("token");
}

export async function getCookiePaginas1() {
  return getSessionItem("paginas1");
}

export async function getCookiePaginas2() {
  return getSessionItem("paginas2");
}

export async function getCookieAdministradorRol() {
  return getSessionItem("rolId");
}

export async function getCookieAdministradorProveedorId() {
  return getSessionItem("proveedorId");
}

export async function getCookieAdministradorSucursalId() {
  return getSessionItem("sucursalId");
}

export async function getCookieNotificaciones() {
  return getSessionItem("notificaciones");
}

export async function getCookieAdministradorNombrePuesto() {
  const nombre = getSessionItem("nombre");
  const puestoLaboral = getSessionItem("puestoLaboral");

  return { nombre, puestoLaboral };
}

// Función para limpiar los datos de sessionStorage y cookies
export async function clearCookies() {
  sessionStorage.removeItem("token");
  // Eliminar cookies usando un enfoque manual:
  document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

  sessionStorage.removeItem("paginas1");
  sessionStorage.removeItem("paginas2");
  sessionStorage.removeItem("proveedores");

  sessionStorage.removeItem("nombre");
  sessionStorage.removeItem("puestoLaboral");
  sessionStorage.removeItem("rolId");
  sessionStorage.removeItem("proveedorId");
  sessionStorage.removeItem("sucursalId");

  sessionStorage.removeItem("sucursales");
}
